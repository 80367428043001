<div class="modal-header" *ngIf="options.title">{{options.title | translate}}</div>

<div class="modal-body" [innerHTML]="options.message | translate"></div>

<div class="modal-footer justify-content-end">

  <button
    *ngIf="options.cancelText"
    class="btn btn-outline-secondary me-2"
    (click)="onClose()">{{options.cancelText | translate}}</button>

  <button class="btn btn-primary" (click)="onConfirm()">{{options.confirmText | translate}}</button>

</div>
