import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

import {ApiAbstract} from '@core/abstracts/api.abstract';
import {AuthLoginResponseModel} from '@core/models/api/response/auth-login.response.model';
import {AuthLoginRequestModel} from '@core/models/api/request/auth-login.request.model';
import {ProfileModel} from '@core/models/api/response/profile.model';
import {SKIP_ERROR_HANDLING_PARAMS} from '@core/utils/url.utils';


@Injectable({
  providedIn: 'root'
})
export class IdentityService extends ApiAbstract {

  constructor(
    protected http: HttpClient
  ) {
    super('identity');
  }

  login(payload: AuthLoginRequestModel): Observable<AuthLoginResponseModel> {
    return this.http.post<AuthLoginResponseModel>(this.getJooApiUrl('auth/token'), payload);
  }

  refreshToken(refresh: string): Observable<AuthLoginResponseModel> {
    return this.http.post<AuthLoginResponseModel>(
      this.getJooApiUrl('auth/token/refresh'), {refresh}, {params: SKIP_ERROR_HANDLING_PARAMS}
    );
  }

  logout(): Observable<any> {
    return this.http.post<AuthLoginResponseModel>(
      this.getJooApiUrl('auth/logout'), null, {params: SKIP_ERROR_HANDLING_PARAMS}
    );
  }

  getCurrUserProfile(accessToken: string): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(this.getJooApiUrl('profile'), {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${accessToken}`
      })
    });
  }

  getProfile(id: number | string): Observable<ProfileModel> {
    return this.http.get<ProfileModel>(this.getJooApiUrl(`profiles/${id}`));
  }

  getProfiles(params?: any): Observable<ProfileModel[]> {
    return this.http.get<ProfileModel[]>(this.getJooApiUrl('profiles'), {params});
  }
}
