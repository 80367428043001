export const getFinalUrl = (mainUrl: string, extraBranch?: string | number): string => {
  if (extraBranch) {
    mainUrl += `/${extraBranch}`;
  }

  return `${mainUrl}/`;
};

export const extractHttpErrorMessage = data => {
  if (!data || typeof data === 'string') {
    return data;
  }
  if (Array.isArray(data)) {
    return extractHttpErrorMessage(data[0]);
  } else {
    const objKeys = Object.keys(data);
    return extractHttpErrorMessage(data[objKeys[0]]);
  }
};

export const LIST_ALL_PARAMS = <any>{
  page_size: 1000
};

export const SKIP_ERROR_HANDLING_PARAMS = <any>{
  skip_error_handling: true
};

export const SKIP_LOADER_SHOWING_PARAMS = <any>{
  skip_loader_showing: true
};
