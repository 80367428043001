import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext, Store} from '@ngxs/store';
import {Navigate} from '@ngxs/router-plugin';
import moment from 'moment';
import {Moment} from 'moment';
import {filter, isEmpty, isNil} from 'ramda';

import {GroupType, SYSTEM_GROUPS} from '@core/types/group.type';
import {AuthLoginResponseModel} from '@core/models/api/response/auth-login.response.model';
import {GetProfile, Login, Logout, NavigateHome, RemoveToken, UpdateGroup, UpdateToken} from './actions';
import {IdentityService} from '@core/services/api/identity.service';
import {DialogService} from '@core/services/local/dialog.service';

export const getSystemGroups = filter<GroupType>(group => SYSTEM_GROUPS.includes(group));

export interface AuthStateModel {
  token: AuthLoginResponseModel;
  accessTokenExpireDate?: Moment;
  groups: GroupType[];
  currentGroup: GroupType;
}

const defaults = {
  token: null,
  accessTokenExpireDate: null,
  groups: [],
  currentGroup: null,
};

@State<AuthStateModel>({
  name: 'auth',
  defaults
})
@Injectable()
export class AuthState {

  @Selector()
  static token({token}: AuthStateModel): AuthLoginResponseModel {
    return token;
  }

  @Selector()
  static accessTokenExpireDate({accessTokenExpireDate}: AuthStateModel): Moment {
    return accessTokenExpireDate;
  }

  @Selector()
  static isExpert(state: AuthStateModel): boolean {
    return state.currentGroup === 'expert';
  }

  @Selector()
  static isModerator(state: AuthStateModel): boolean {
    return state.currentGroup === 'moderator';
  }

  @Selector()
  static isStudentManager(state: AuthStateModel): boolean {
    return state.groups.includes('student-manager');
  }

  @Selector()
  static isSupport(state: AuthStateModel): boolean {
    return state.groups.includes('support');
  }

  @Selector()
  static currentGroup(state: AuthStateModel): GroupType {
    return state.currentGroup;
  }

  @Selector()
  static systemGroups(state: AuthStateModel): GroupType[] {
    return getSystemGroups(state.groups);
  }

  @Selector()
  static isCourseAndTutorManager(state: AuthStateModel): boolean {
    return state.currentGroup === 'courses-moderator' && state.groups.includes('supervisor-manager');
  }

  @Selector()
  static isCustomTestManager(state: AuthStateModel): boolean {
    return state.currentGroup === 'custom-tests-moderator';
  }

  @Selector()
  static isCourseReporter(state: AuthStateModel): boolean {
    return state.currentGroup === 'courses-moderator' && state.groups.includes('courses-reporter');
  }

  @Selector()
  static isQuestionsModerator(state: AuthStateModel): boolean {
    return state.groups.includes('questions-moderator') || state.groups.includes('moderator');
  }

  constructor(
    private store: Store,
    private dialogService: DialogService,
    private identityService: IdentityService,
  ) {
  }

  @Action(Login)
  Login({patchState}: StateContext<AuthStateModel>, {state}: Login) {
    const decodedAccessToken = JSON.parse(window.atob(state.token.access.split('.')[1]));
    return patchState({...state, accessTokenExpireDate: moment.unix(decodedAccessToken.exp)});
  }

  @Action(UpdateToken)
  UpdateToken({patchState}: StateContext<AuthStateModel>, {token}: UpdateToken) {
    const decodedAccessToken = JSON.parse(window.atob(token.access.split('.')[1]));
    return patchState({token, accessTokenExpireDate: moment.unix(decodedAccessToken.exp)});
  }

  @Action(UpdateGroup)
  UpdateGroup({getState, patchState}: StateContext<AuthStateModel>, {group}: UpdateGroup): void {
    if (getState().currentGroup !== group) {
      patchState({currentGroup: group});
      this.store.dispatch(NavigateHome);
    }
  }

  @Action(RemoveToken)
  RemoveToken({patchState}: StateContext<AuthStateModel>) {
    return patchState(defaults);
  }

  @Action(GetProfile)
  GetProfile({getState, patchState}: StateContext<AuthStateModel>) {
    const {currentGroup, token} = getState();
    if (currentGroup) {
      return;
    }

    return this.identityService.getCurrUserProfile(token.access)
      .toPromise()
      .then(({groups}) => {
        if (isNil(groups)) {
          this.dialogService.error({message: 'Ваш аккаунт не имеет доступа к данной системе'});
          this.store.dispatch(Logout);
          return;
        }
        const systemGroups = getSystemGroups(groups);
        if (isEmpty(systemGroups)) {
          this.dialogService.error({message: 'Ваш аккаунт не имеет доступа к данной системе'});
          this.store.dispatch(Logout);
          return;
        }
        patchState({groups, currentGroup: systemGroups[0]});
        this.store.dispatch(NavigateHome);
      })
      .catch(() => {
        this.store.dispatch(Logout);
      });
  }

  @Action(NavigateHome)
  NavigateHome({getState}: StateContext<AuthStateModel>) {
    const {currentGroup} = getState();
    return this.store.dispatch(currentGroup ? new Navigate([`/${currentGroup}`]) : Logout);
  }

  @Action(Logout)
  Logout({getState, patchState}: StateContext<AuthStateModel>) {
    return this.identityService.logout()
      .toPromise()
      .finally(() => {
        patchState(defaults);
        this.store.dispatch(new Navigate(['/auth']));
      });
  }
}
