export type GroupType = 'testolog' | 'expert' | 'moderator' | 'support' | 'module-comment-manager' | 'courses-moderator' | 'courses-reporter' |
  'broadcast-moderator' | 'supervisor-manager' | 'offline-teacher' | 'offline-courses-moderator' | 'custom-tests-moderator' |
  'stream-manager' | 'subject-manager' | 'sales-manager' | 'trainer-manager' | 'teacher' | 'supervisor' | 'student-manager' |
  'trial-test-manager' | 'trial-test-variant-manager' | 'trial-test-checker' | 'polls-manager' | 'questions-moderator';

export const SYSTEM_GROUPS: GroupType[] = [
  'testolog', 'expert', 'moderator', 'module-comment-manager', 'supervisor-manager', 'stream-manager', 'subject-manager', 'sales-manager',
  'trainer-manager', 'teacher', 'supervisor', 'polls-manager', 'questions-moderator',
  'trial-test-manager',
  'trial-test-variant-manager',
  'trial-test-checker',
];

export const GROUP_LABEL_MAP = {
  'testolog': 'Тестолог',
  'expert': 'Эксперт',
  'moderator': 'Модератор',
  'module-comment-manager': 'Менеджер комментариев модуля',
  'custom-tests-moderator': 'Модератор кастомных тестов',
  'supervisor-manager': 'Менеджер кураторов',
  'stream-manager': 'Менеджер потока',
  'subject-manager': 'Менеджер учебных планов',
  'sales-manager': 'Менеджер продаж',
  'trainer-manager': 'Менеджер тренажера',
  'teacher': 'Учитель',
  'supervisor': 'Супервайзор',
  'polls-manager': 'Менеджер опросов',
  'trial-test-manager': 'Менеджер пробных тестов',
  'trial-test-variant-manager': 'Менеджер вариантов пробных тестов',
  'trial-test-checker': 'Проверяющий пробных тестов',
  'questions-moderator': 'Модератор вопросов'
};
