import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowDown,
  faArrowUp,
  faArrowLeft,
  faSave,
  faTrash,
  faBan,
  faPen,
  faPenSquare,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faMinusSquare,
  faSort,
  faSortAmountUp,
  faSortAmountDown,
  faSortUp,
  faSortDown,
  faTrashAlt,
  faTimes,
  faEye,
  faPause,
  faPlay,
  faSearch,
  faCheck,
  faImage,
  faComment,
  faFlagCheckered,
  faFile, faDownload, faSpinner, faMinusCircle, faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import {SizeProp} from '@fortawesome/angular-fontawesome'

@Component({
  selector: 'shared-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {

  @Input() defaultIcon: string;

  @Input() color: string; // 'white' | 'primary';

  @Input() class = '';

  @Input() size: SizeProp | undefined;

  @Input() hover = false;

  @Input() spin = false;

  @Output() tap = new EventEmitter();

  icon: IconDefinition;

  @Input()
  set name(name: string) { // 'pen' | 'plus' | 'arrow-up' | 'arrow-down';
    switch (name ? name : this.defaultIcon) {
      case 'save':
        this.icon = faSave;
        break;
      case 'trash':
        this.icon = faTrash;
        break;
      case 'ban':
        this.icon = faBan;
        break;
      case 'pen':
        this.icon = faPen;
        break;
      case 'plus':
        this.icon = faPlus;
        break
      case 'pen-square':
        this.icon = faPenSquare;
        break;
      case 'plus-circle':
        this.icon = faPlusCircle;
        break;
      case 'plus-square':
        this.icon = faPlusSquare;
        break;
      case 'minus-square':
        this.icon = faMinusSquare;
        break;
      case 'minus-circle':
        this.icon = faMinusCircle;
        break;
      case 'arrow-up':
        this.icon = faArrowUp;
        break;
      case 'arrow-down':
        this.icon = faArrowDown;
        break;
      case 'arrow-left':
        this.icon = faArrowLeft;
        break;
      case 'arrow-right':
        this.icon = faArrowRight;
        break;
      case 'angle-double-left':
        this.icon = faAngleDoubleLeft;
        break;
      case 'angle-double-right':
        this.icon = faAngleDoubleRight;
        break;
      case 'angle-left':
        this.icon = faAngleLeft;
        break;
      case 'angle-right':
        this.icon = faAngleRight;
        break;
      case 'angle-up':
        this.icon = faAngleUp;
        break;
      case 'angle-down':
        this.icon = faAngleDown;
        break;
      case 'sort':
        this.icon = faSort;
        break;
      case 'sort-amount-up':
        this.icon = faSortAmountUp;
        break;
      case 'sort-amount-down':
        this.icon = faSortAmountDown;
        break;
      case 'eye':
        this.icon = faEye;
        break;
      case 'sort-up':
        this.icon = faSortUp;
        break;
      case 'sort-down':
        this.icon = faSortDown;
        break;
      case 'trash-alt':
        this.icon = faTrashAlt;
        break;
      case 'search':
        this.icon = faSearch;
        break;
      case 'times':
        this.icon = faTimes;
        break;
      case 'check':
        this.icon = faCheck;
        break;
      case 'image':
        this.icon = faImage;
        break;
      case 'comment':
        this.icon = faComment;
        break;
      case 'flag-checkered':
        this.icon = faFlagCheckered;
        break;
      case 'file':
        this.icon = faFile;
        break;
      case 'download':
        this.icon = faDownload;
        break;
      case 'spinner':
        this.icon = faSpinner;
        break;
      case 'pause':
        this.icon = faPause;
        break;
      case 'play':
        this.icon = faPlay;
        break;
      default:
        this.icon = null;
    }
  }
}
