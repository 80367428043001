import {Injectable} from '@angular/core';
import {BsModalService} from 'ngx-bootstrap/modal';

import {DialogModel} from '@core/models/local/dialog.model';
import {DialogComponent} from '@core/components/dialog/dialog.component';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private bsModalService: BsModalService
  ) {
  }

  show(options: DialogModel) {
    this.bsModalService.show(DialogComponent, {initialState: {options}});
  }

  error(options: DialogModel) {
    if (!options.title) {
      options.title = 'errors.title';
    }

    this.bsModalService.show(DialogComponent, {initialState: {options}});
  }
}
