import {environment} from '@env';
import {getFinalUrl} from '@core/utils/url.utils';


export abstract class ApiAbstract {

  constructor(
    protected uri: string
  ) {
  }

  protected getUrl(branch?: string | number): string {
    let url = `${environment.jooApiBaseUrlV1}/${this.uri}`;
    if (branch) url += `/${branch}`;
    return `${url}/`;
  }

  protected getUrlV2(branch?: string | number): string {
    let url = `${environment.jooApiBaseUrlV2}/${this.uri}`;
    if (branch) url += `/${branch}`;
    return `${url}/`;
  }

  /**
   * @deprecated getJooApiUrl no longer supported, use getUrl instead
   */
  protected getJooApiUrl(extraBranch?: string | number): string {
    return getFinalUrl(`${environment.jooApiUrl}/${this.uri}`, extraBranch);
  }

  /**
   * @deprecated getJooApiUrl no longer supported, use getUrl instead
   */
  protected getJooApiCoursesUrl(extraBranch?: string | number): string {
    return getFinalUrl(`${environment.jooApiCoursesUrl}/${this.uri}`, extraBranch);
  }

  /**
   * @deprecated getJooApiUrl no longer supported, use getUrl instead
   */
  protected getJooApiSupportUrl(extraBranch?: string | number): string {
    return getFinalUrl(`${environment.jooApiSupportUrl}/${this.uri}`, extraBranch);
  }
}
